<template>
  <nav class="nav nav-pills flex-column flex-sm-row">
    <router-link class="nav-link" :to="'/account/' + accountId + '/status'">Status</router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/connections'">Connections</router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/displays-and-features'">Displays &amp; Features
    </router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/locations'">Locations</router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/users'">Users</router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/api-keys'">API Keys</router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/call-tracking'">Call Tracking</router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/settings'">Account Settings</router-link>
    <router-link class="nav-link" :to="'/account/' + accountId + '/performance-reports'">Performance Reports</router-link>
  </nav>
</template>

<script>

export default {
  name: 'PageNav',
  props: {
    accountId: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.nav {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ccc;
}
</style>
