<template>
  <div class="toast position-absolute bottom-0 end-0 p-3" role="alert" aria-live="assertive" aria-atomic="true"
       :id="errorToastId">
    <div class="toast-header">
      <strong class="me-auto">Error</strong>
      <small>right now</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body" v-if="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "ErrorToast",
  props: {
    errorToastId: String,
    message: String
  },
});
</script>
