import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0534594e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row gx-5" }
const _hoisted_3 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorToast = _resolveComponent("ErrorToast")!
  const _component_MainNav = _resolveComponent("MainNav")!
  const _component_PageNav = _resolveComponent("PageNav")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ErrorToast, {
      message: _ctx.errorMessage,
      id: _ctx.errorToastId
    }, null, 8, ["message", "id"]),
    _createVNode(_component_MainNav),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.accountName), 1)
        ])
      ]),
      _createVNode(_component_PageNav, {
        key: "accountNum",
        accountId: _ctx.accountNum
      }, null, 8, ["accountId"]),
      _createVNode(_component_router_view, {
        onThrowError: _ctx.throwError,
        route_params: _ctx.$route.params
      }, null, 8, ["onThrowError", "route_params"])
    ])
  ], 64))
}