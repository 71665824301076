<template>
  <nav class="navbar navbar-expand-md navbar-light">
    <a class="navbar-brand" href="#">
      <img src="@/assets/logo.svg" />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="websitesDropdown" role="button" data-bs-toggle="dropdown"
             aria-expanded="false">
            Websites
          </a>
          <ul class="dropdown-menu" aria-labelledby="websitesDropdown">
            <li><a class="dropdown-item" href="#">Website</a></li>
            <li><a class="dropdown-item" href="#">Another Website</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="'/account/' + accountNum + '/team-members'">Team Members</router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click="signOut()">Sign Out</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"

export default {
  name: 'MainNav',
  setup() {
    const store = useStore()
    const user = JSON.parse(localStorage.getItem('user') || '{}')

    const signOut = () => {
      store
      .dispatch(Actions.LOGOUT)
    }

    const accountNum = store.getters.activeAccountInfo.id

    return {
      accountNum,
      signOut,
      user
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
  margin-bottom: 1.2rem;
  background: #01181c;
  text-transform: uppercase;
}

.navbar-brand {
  width: 150px;

  img {
    width: 100%;
  }
}
.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: #f9423a;
}
</style>
