
import { defineComponent } from "vue"
import MainNav from '@/components/MainNav.vue'
import PageNav from '@/components/PageNav.vue'
import store from '@/store'
import ErrorToast from "@/components/errors/Toast.vue"
import { Toast } from "bootstrap"

export default defineComponent({
  name: "Layout",
  components: {
    MainNav,
    PageNav,
    ErrorToast
  },
  data() {
    return { errorMessage: "", errorToastId: "" }
  },
  computed: {
    accountName() {
      return store.getters.activeAccountInfo.details.name
    },
    accountNum() {
      return String(store.getters.activeAccountInfo.id)
    }
  },
  methods: {
    async throwError(errorMessage: string, errorToastId: string) {
      this.errorMessage = errorMessage
      this.errorToastId = errorToastId

      let toast

      try {
        toast = await this.toastIdSet(errorToastId)
      } catch (e) {
        console.error("cannot create toast")
      }


      if (toast) {
        const toastElement = document.getElementById(String(this.errorToastId))
        const toastInstance = Toast.getOrCreateInstance(toastElement)
        toastInstance.show()
      }


    },
    async toastIdSet(errorToastId) {

      return new Promise((resolve, reject) => {
        if (this.errorToastId == errorToastId) {
          resolve(true)
        }
        reject(false)
      })

    }
  }
});
